import React from "react";
import BureauLogo from "assets/img/whatwedo-img/BureauLogo.jpg";
import IbdcLogo from "assets/img/whatwedo-img/IbdcLogo.png";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import whatToDoStyle from "../WhatWeDoStyle";

const useStyles = makeStyles(whatToDoStyle);

export const Bureau = () => {
  const classes = useStyles();
  return (
    <div style={{ height: "100%" }}>
      <Grid className={classes.contentBody4}>
        <div style={{ position: "relative" }}>
          <Grid
            style={{
              color: "#131747",
              position: "absolute",
              top: "0px",
              right: "42px",
            }}
          >
            <Typography variant="h6" style={{ width: "100%" }}>
              <span className={classes.boldTypeHeadline}>Bureau</span> de Change
            </Typography>
          </Grid>
          <Grid container style={{ position: "relative" }}>
            <Grid className={classes.BureauUndergroundLayer}></Grid>
            <Grid item className={classes.InvestmentBankingDiv2} xs={12}>
              <Grid>
                <img
                  src={BureauLogo}
                  alt="Bureau pic"
                  className={classes.contentBigImg}
                />
              </Grid>
              <Grid item xs={12} md={8} className={classes.contents}>
                <Typography className={classes.contentTypography}>
                  Our Bureau De Change business is tailored to meet the needs of
                  our clients, need for speed and convenience. We are regulated
                  by the CBN and we facilitate foreign currency trade and
                  transfers to and on behalf of our customers, we keep evolving
                  and find more ways to solve our customer foreign currency
                  needs in an innovative way.
                </Typography>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"https://sebastianbdc.com/"}
                >
                  <Grid className={classes.contentSmailImgDiv}>
                    <img
                      src={IbdcLogo}
                      alt="iBDC"
                      className={classes.contentSmailImage}
                    />
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
