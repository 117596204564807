/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import sebastian from "../../assets/images/Sebastian.png";
import canaryPointLogo from "../../assets/images/canaryPointLogo.png";
import SwiftAllied from "../../assets/images/Swift&Allied.png";
import Assets from "../../assets/images/Assets.png";
import InstantCash from "../../assets/images/InstantCash.png";
import MonieSTree from "../../assets/images/MonieSTree.png";
import sebastianBig from "../../assets/images/SebastianBDClogo.png";
import SwiftAlliedBig from "../../assets/images/Swift&AlliedPartners.png";
import AssetsBig from "../../assets/images/Assestlogo.png";
import InstantCashBig from "../../assets/images/instanChash.png";
import MonieSTreeBig from "../../assets/images/MonieTree.png";
import "./Homepage.css";
import { useHistory } from "react-router-dom";
const HomeSubsidiaries = () => {
  const history = useHistory();
  const logoPicture = [
    { img: Assets, url: "https://sebastianbdc.com/" },
    { img: SwiftAllied, url: "https://www.swiftallied.com/" },
    { img: sebastian, url: "https://sebastianbdc.com/" },
    { img: InstantCash, url: "" },
    { img: MonieSTree, url: "" },
  ];
  return (
    <div className="grand-container">
      <div className="containerflexImg">
        <div className="logo-container">
          <div className="logo-sack">
            <div className="logo-img1">
              <img
                src={AssetsBig}
                alt="sebastian"
                width="auto"
                height="auto"
                onClick={() => window.open("https://www.assets-mfb.com")}
              />
            </div>
            <div className="logo-img2">
              <img
                src={SwiftAlliedBig}
                alt="sebastian"
                width="auto"
                height="auto"
                onClick={() => window.open("https://www.swiftallied.com/")}
              />
            </div>
            <div className="logo-img3">
              <img
                src={sebastianBig}
                alt="sebastian"
                width="auto"
                height="auto"
                onClick={() => window.open("https://sebastianbdc.com/")}
              />
            </div>
            <div className="logo-img4">
            <img
              src={canaryPointLogo}
              alt="sebastian"
              width="auto"
              height="auto"
              onClick={() => window.open("https://canarypointcs.com/")}
              />
            </div>
            <div className="logo-img6">
              <img
                src={InstantCashBig}
                alt="sebastian"
                width="auto"
                height="auto"
                onClick={() => window.open("https://www.instantcashng.com/")}
              />
            </div>
            <div className="logo-img7">
              <img
                src={MonieSTreeBig}
                alt="sebastian"
                width="auto"
                height="auto"
              />
            </div>
          </div>
        </div>

        <div className="text-container">
          <h1>Our Subsidiaries</h1>
          <p>
            CPCSL is a holding company for various companies that offer a range
            of investment, microfinance, currency exchange and financial
            services to the public ensuring that their financial needs are
            getting the required attention with the right tools and products.
            <button
              className="showmore-btn"
              onClick={() => history.push("subsidiaries")}
            >
              .…More
            </button>
          </p>
        </div>
        <div className="logo-scroll-container">
          {logoPicture.map((logo, index) => {
            if (!logo.url) {
              // delete window.open();
              return (
                <img
                  src={logo.img}
                  alt="sebastian"
                  onClick={() => history.push("/")}
                  key={index}
                  className="logo-scroll onscrollviewimg"
                />
              );
            } else {
              return (
                <img
                  src={logo.img}
                  alt="sebastian"
                  onClick={() => window.open(logo.url)}
                  key={index}
                  className="logo-scroll onscrollviewimg"
                />
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default HomeSubsidiaries;
