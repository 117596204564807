import React from "react";
import InvestmentBankingImg from "assets/img/whatwedo-img/Investment-Banking.jpg";
import SwiftAlliedPartnerslogo from "assets/img/whatwedo-img/Swift-Allied-Partners-logo.png";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import whatToDoStyle from "../WhatWeDoStyle";

const useStyles = makeStyles(whatToDoStyle);

export const Investment = () => {
  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Grid
        item
        xs={12}
        md={12}
        className={classes.contentBody}
        style={{ marginTop: "4rem" }}
      >
        <div style={{ height: "100%" }}>
          <Grid style={{ marginLeft: "20px", color: "#131747" }}>
            <Typography variant="h6" style={{ width: "100%" }}>
              <span className={classes.boldTypeHeadline}>Investment</span>{" "}
              Banking
            </Typography>
          </Grid>
          <Grid style={{ position: "relative" }}>
            <Grid className={classes.undergroundLayer}></Grid>
            <Grid item className={classes.InvestmentBankingDiv} xs={12}>
              <Grid>
                <img
                  src={InvestmentBankingImg}
                  alt="Investment Banking"
                  className={classes.contentBigImg}
                />
              </Grid>
              <Grid item xs={12} md={8} className={classes.contents}>
                <Typography className={classes.contentTypography}>
                  Our highly-skilled Investment Banking team work across various
                  asset classes, industries and currencies to provide first
                  class investment services, financing solutions, foreign
                  currency investments, wealth management dedicated to helping
                  our clients (HNI, organisations, retail investors) build and
                  sustain wealth.
                </Typography>
                <a
                  target="_blank"
                  href={"https://www.swiftallied.com/"}
                  rel="noreferrer"
                >
                  <Grid className={classes.contentSmailImgDiv}>
                    <img
                      src={SwiftAlliedPartnerslogo}
                      alt="Swift-Allied-Partners-logo"
                      className={classes.contentSmailImage}
                    />
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
