import React, { useState } from "react";
import styles from "../../components/HomePageFolder/Homepage.module.css";
import stylesMedia from "../../components/MediaFolder/Media.module.css";
import { Link } from "react-router-dom";

const RecentCardMedia = ({ id, title, body, image, linkTo }) => {
  const [more, setMore] = useState(false);

  return (
    <div className={stylesMedia.spacingincards}>
      <Link to={{ pathname: linkTo }}>
        <article className={styles.cardsizing}>
          {/* <div className={styles.imgWrapper}> */}
          <img
            className={stylesMedia.imageStyle}
            src={image}
            alt="img-alt"
            width="500px"
            height="auto"
          />
          {/* </div> */}
          <footer
            style={{
              paddingTop: ".1rem",
              paddingBottom: "2rem",
              paddingLeft: "1.2rem",
              paddingRight: ".6rem",
            }}
          >
            <div>
              <p className={stylesMedia.headerTextMedia}>
                {`${title.substring(0, 25)}....`}
              </p>
            </div>
            <div>
              <p className={stylesMedia.bodyText}>
                {more ? body : body && `${body.substring(0, 100)}`}
                <button
                  onClick={() => setMore(!more)}
                  className={styles.btnshowmore}
                >
                  {more ? "Less" : "Read More"}
                </button>
              </p>
            </div>
          </footer>
        </article>
      </Link>
    </div>
  );
};

export default RecentCardMedia;
