import financialImage from "../../assets/images/financialImage.png";
import patnership from "../../assets/images/patnership.png";
import styles from "../../components/HomePageFolder/Homepage.module.css";
export const homeSlide = [
  {
    imgageslide: financialImage,
    text: (
      <p className={styles.homecarouseltext}>
        <span className={styles.nameboldedfinancial}>Financial</span> Solutions
        For <span className={styles.nameboldedfinancial}>Tomorrow</span>
      </p>
    ),
  },
  {
    imgageslide: patnership,
    text: (
      <p className={styles.homecarouseltext}>
        <span className={styles.nameboldedfinancial}>Partnership</span>
        &nbsp;for <span className={styles.nameboldedfinancial}>
          Better
        </span>{" "}
        Finances
      </p>
    ),
  },
];
export const listenerCircleHomeslide = [<div></div>, <div></div>];
