import React, { useLayoutEffect } from "react";
import CanaryColony from "../components/Careersfolder/CanaryColony";
import CareersCulture from "../components/Careersfolder/CareersCulture";
import CultureCoreValue from "../components/Careersfolder/CultureCoreValue";
import JoinOurTeam from "../components/Careersfolder/JoinOurTeam";
import Carousel from "../components/Careersfolder/Carousel";

const Careers = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CareersCulture />
      <CanaryColony />
      <CultureCoreValue />
      <JoinOurTeam />
      <Carousel />
    </>
  );
};

export default Careers;
