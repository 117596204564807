import IbcdLogo from "assets/img/whatwedo-img/IbdcLogo.png";
import swiftAlliedLogo from "assets/img/whatwedo-img/swiftAlliedLogo.png";
import MonieSTree from "../../assets/images/MonieSTree.png";
import assetsMicrofinanceLogo from "assets/img/whatwedo-img/asetmfb.png";
import instantCashLogo from "assets/img/whatwedo-img/instantCash.gif";
import nairagramLogo from "assets/img/whatwedo-img/companyLogo4.png";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import style from "./SubsidiariesStyle";

const useStyles = makeStyles(style);

const Content1 = () => {
  const classes = useStyles();
  const url = `https://sebastianbdc.com/`;
  function handleClickOne() {
    window.open(url);
  }
  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Sebastian BDC Ltd
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={IbcdLogo}
                    alt="IBDC logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button} onClick={handleClickOne}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                Sebastian BDC Ltd. Is a member of the CPCSL group of companies.
                Sebastian is a CBN licensed Bureau De Change company, whose
                services include but not limited to the buying and selling of
                foreign currency such as US Dollars, British Pounds and Euros.
                Sebastian BDC offers several competitive advantages in the
                market that makes it superior and unique from other BDCs in the
                Forex market. Our service extends to delivery services at a
                minimal fee for customers that purchase large amounts of
                currency , deposit of forex bought directly into the customer’s
                domiciliary account or the Naira equivalent into their Naira
                accounts.
              </Typography>

              <Grid className={classes.buttonGrid2}>
                <Button className={classes.button} onClick={handleClickOne}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
const Content2 = () => {
  const classes = useStyles();
  const url = `https://www.swiftallied.com/`;
  function handleClick() {
    window.open(url);
  }
  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Swift & Allied Partners
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <img
                  src={swiftAlliedLogo}
                  alt="swift-Allied logo"
                  className={classes.SubsidLogoIbdc}
                />
              </Grid>

              <Grid className={classes.buttonGrid}>
                <Button className={classes.button} onClick={handleClick}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                Swift and Allied is a member of the CPCSL group, as a consumer
                finance company we provide credit facilities to a diverse range
                of businesses and individuals who need significant funds to
                scale. Our team of specialist offers business advisory to our
                clients to set them up for success.
              </Typography>
              <Grid className={classes.buttonGrid2}>
                <Button className={classes.button} onClick={handleClick}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
const Content3 = () => {
  const classes = useStyles();
  const url = `https://www.assets-mfb.com/`;
  function handleClickthree() {
    window.open(url);
  }
  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Assets Microfinance Bank Ltd. (AMFB)
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent2}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={assetsMicrofinanceLogo}
                    alt="assets-Microfinance-Logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button} onClick={handleClickthree}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                Asset MFB is the CBN licensed subsidiary of CPCSL. AMFB is set
                up to empower Nigerians with greater financial freedom by making
                access to loans faster, simpler, safer and more affordable. At
                AMFB we are focused on providing customer centric, fast and need
                oriented banking services to suitable and qualified Individuals
                and Corporate organizations in a responsible manner.
              </Typography>
              <Grid className={classes.buttonGrid2}>
                <Button className={classes.button} onClick={handleClickthree}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Content4 = () => {
  const classes = useStyles();
  const url = `https://www.mynairagram.com/`;
  function handleClickfour() {
    window.open(url);
  }
  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Nairagram
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent2}>
              <Grid className={classes.SubsidLogoIbdcDiv2}>
                <a href="/#">
                  <img
                    src={nairagramLogo}
                    width="100%"
                    alt="nairagram logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button} onClick={handleClickfour}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                Nairagram partners with CPCSL to accelerate it's remittance
                business across the globe. Nairagram is a full-service,
                technology and innovation driven online remittance company that
                specializes solely in money transfers from the United States and
                Europe to Nigeria.
              </Typography>
              <Grid className={classes.buttonGrid2}>
                <Button className={classes.button} onClick={handleClickfour}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const Content5 = () => {
  const classes = useStyles();
  const url = `https://www.instantcashng.com/`;
  function handleClickfive() {
    window.open(url);
  }

  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              Instant Cash
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={instantCashLogo}
                    alt="instant Cash logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button} onClick={handleClickfive}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                It is essentially a mobile application that allows customers to
                borrow money on the go.
              </Typography>
              <Grid className={classes.buttonGrid2}>
                <Button className={classes.button} onClick={handleClickfive}>
                  Visit Website
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};
const Content6 = () => {
  const classes = useStyles();

  return (
    <div>
      <Container>
        <Grid container>
          <Grid
            item
            xs={10}
            sm={8}
            md={8}
            className={classes.SebastianHeadGrid}
          >
            <Typography variant="h5" className={classes.SebastianTypoText}>
              MonieTree
            </Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.SubsidWebDetails}>
          <Grid item xs={12} md={9} className={classes.SubsidDetailsGrid}>
            <Grid item className={classes.SubsidLogoContent}>
              <Grid className={classes.SubsidLogoIbdcDiv}>
                <a href="/#">
                  <img
                    src={MonieSTree}
                    alt="monieTree logo"
                    className={classes.SubsidLogoIbdc}
                  />
                </a>
              </Grid>
              <Grid className={classes.buttonGrid}>
                <Button className={classes.button}>Coming Soon</Button>
              </Grid>
            </Grid>
            <Grid item xs={12} md={10} className={classes.IbdcContent}>
              <Typography className={classes.typographybody2}>
                MonieTree is a financial app designed to meet all your financial
                needs, from savings and investments, to quick loans and bill
                payments.
              </Typography>
              <Grid className={classes.buttonGrid2}>
                <Button className={classes.button}>Coming Soon</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export { Content1, Content2, Content3, Content4, Content5, Content6 };
