import React from "react";
import MicrofinanceBankingImg from "assets/img/whatwedo-img/Microfinance-Banking.jpg";
import MicrofinanceLog0 from "assets/img/whatwedo-img/microAssets-Logo.png";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import whatToDoStyle from "../WhatWeDoStyle";

const useStyles = makeStyles(whatToDoStyle);

export const Microfinance = () => {
  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Grid className={classes.contentBody2}>
        <div style={{ position: "relative" }}>
          <Grid
            style={{
              color: "#131747",
              position: "absolute",
              top: "0px",
              right: "40px",
            }}
          >
            <Typography variant="h6" style={{ width: "100%" }}>
              <span className={classes.boldTypeHeadline}>Microfinance</span>{" "}
              Banking
            </Typography>
          </Grid>
          <Grid container style={{ position: "relative" }}>
            <Grid className={classes.MicrofinanceUndergroundLayer}></Grid>
            <Grid item className={classes.InvestmentBankingDiv2} xs={12}>
              <Grid>
                <img
                  src={MicrofinanceBankingImg}
                  alt="Microfinance Banking"
                  className={classes.contentBigImg}
                />
              </Grid>
              <Grid item xs={12} md={8} className={classes.contents}>
                <Typography className={classes.contentTypography}>
                  We provide ease of access to micro, small and medium
                  enterprises and SMEs with sustainable finance in micro loans,
                  asset finance, SME loans and other short-term facilities.
                </Typography>
                <a
                  target="_blank"
                  href={"https://www.assets-mfb.com/"}
                  rel="noreferrer"
                >
                  <Grid className={classes.contentSmailImgDiv}>
                    <img
                      src={MicrofinanceLog0}
                      alt="Swift-Allied-Partners-logo"
                      className={classes.contentSmailImage}
                    />
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
