import React from "react";
import NairagramLogo from "assets/img/whatwedo-img/nairagramLogo.gif";
import moneyImage from "assets/img/whatwedo-img/moneyImage.jpg";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import whatToDoStyle from "../WhatWeDoStyle";

const useStyles = makeStyles(whatToDoStyle);

export const Remittance = () => {
  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Grid className={classes.contentBody3}>
        <div>
          <Grid style={{ marginLeft: "20px", color: "#131747" }}>
            <Typography
              variant="h6"
              style={{ width: "100%", fontFamily: "Gotham Rounded Medium" }}
            >
              <strong>Remittance</strong>
            </Typography>
          </Grid>
          <Grid container style={{ position: "relative" }}>
            <Grid className={classes.remittanceUndergroundLayer}></Grid>
            <Grid item className={classes.InvestmentBankingDiv} xs={12}>
              <Grid>
                <img
                  src={moneyImage}
                  alt="money-pix"
                  className={classes.contentBigImg}
                />
              </Grid>
              <Grid item xs={12} md={8} className={classes.contents}>
                <Typography className={classes.contentTypography}>
                  We understand that the Nigerian community needs to send money
                  home and we have created an instant payment gateway. When you
                  need to transfer funds to residents or businesses located in
                  Nigeria, few competitors are able to offer the same kind of
                  caring service we easily provide. We maintain highly-secure
                  dedicated servers and provide a very comfortable user-friendly
                  online environment, to guarantee your transfer in 24 hours or
                  less!
                </Typography>
                <div style={{ paddingTop: "1rem" }}></div>
                <a
                  target="_blank"
                  href={"https://www.mynairagram.com/"}
                  rel="noreferrer"
                >
                  <Grid className={classes.contentSmailImgDiv}>
                    <img
                      src={NairagramLogo}
                      alt="Nairagram-logo"
                      className={classes.contentSmailImage}
                    />
                  </Grid>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};
