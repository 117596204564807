import React, { useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { people, directors } from "./OurPeopleData";
import { AiOutlineClose } from "react-icons/ai";
import "./About.css";

const Background = styled.body`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3) !important;
  position: absolute;
  bottom: 4rem;
  top: 0;
  right: 0;
  left: 0;
`;

const ModalWrapper = styled.div`
  max-width: 900px;
  height: 600px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  position: relative;
  margin-top: 3rem;
  margin-left: auto;
  margin-right: auto;
  z-index: 10;
  border-radius: 10px;
  overflow-y: scroll;
  top: 16rem;
  @media (max-width: 1024px) {
    top: 8rem;
  }
  @media (max-width: 768px) {
    top: 5rem;
  }
`;

export const Modal = ({ showModal, setShowModal, personId, section }) => {
  // UseRef Used to reference same node element whenever the it is created and destructured in the dom// {current: null}
  const modalRef = useRef();
  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal]
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    // Clean up function of Useeffect by removing the keypress event
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  let mapper;

  if (section === "people") {
    mapper = people;
  } else if (section === "director") {
    mapper = directors;
  }

  return (
    <>
      {showModal
        ? // eslint-disable-next-line
          mapper.map((people, i) => {
            if (people.id === personId) {
              return (
                <Background onClick={closeModal} ref={modalRef}>
                  <animated.div style={animation}>
                    <ModalWrapper showModal={showModal}>
                      <div>
                        <img
                          src={people.img}
                          alt="Management"
                          width="233"
                          height="233"
                          className="content-centered-popup"
                        />
                        <p className="name-styles-stakeholders">
                          {people.name}
                        </p>
                        <p className="popup-title-text">{people.title}</p>
                        <p className="popup-main-text-width">
                          {people.description}
                        </p>
                        <span>
                          <AiOutlineClose
                            className="close-btn-position"
                            onClick={() => setShowModal((prev) => !prev)}
                          />
                        </span>
                      </div>
                    </ModalWrapper>
                  </animated.div>
                </Background>
              );
            }
          })
        : null}
    </>
  );
};
