import React, { useEffect, useState } from "react";
import axios from "axios";
// import moment from "moment";
import Container from "@material-ui/core/Container";
import styles from "../../components/MediaFolder/Media.module.css";
import { linkedin, facebook, instagram, twitter } from "./Logo";
import CanaryFountionBanner from "../../components/CanaryFoundation/CanaryFountionBanner";
// import { Images } from "../../shared/themes";
import { useParams, Link } from "react-router-dom";
import MDEditor from "@uiw/react-md-editor";

import { BASE_URL } from "../../config";

const FoundationPost = ({ history }) => {
  const [loading, setLoading] = useState(false);
  const [blogData, setBlogData] = useState([]);

  const { postId } = useParams();

  useEffect(() => {
    getBlogPost();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scroll(0, 0);
    getBlogPost();
    // eslint-disable-next-line
  }, [postId]);

  const getBlogPost = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/csrs/${postId}`);
      const data = await response.data;
      setBlogData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error.message);
    }
  };

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  // const truncate = (str, no_words) => {
  //   return str.split(" ").splice(0, no_words).join(" ");
  // };

  // const currentPath = history.location.pathname.replace("/media/", "");
  // const currentPathNavIndex = history.location.pathname.lastIndexOf("/");
  const imageUrl =
    blogData.images &&
    blogData.images.length &&
    BASE_URL + blogData.images[0].url;

  return (
    <div>
      <CanaryFountionBanner />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 100,
          }}
        >
          <h3 style={{ color: "#666" }}>Loading...</h3>
        </div>
      ) : (
        <>
          <div className={styles.foundation_cover}>
            <p>
              <Link to="/canary-foundation" className={styles.foundation_nav}>
                Canary Foundation
              </Link>{" "}
              &gt;{" "}
              <span className={styles.foundation_nav1}>{blogData.title}</span>
            </p>
          </div>
          <Container className={styles.containerWrapper}>
            <div className={styles.teamBondingflex1}>
              <div className={styles.teammajorcontent2}>
                <div
                  style={{
                    display: "flex",
                    // height: "50px",
                    justifyContent: "space-between",
                    // marginBottom: 10,
                  }}
                >
                  <p className={styles.foundation_header}>{blogData.title}</p>
                  {/* <p className={styles.bondingDate11}>
                {moment(blogData.published_at).format("Do MMMM, YYYY")}
              </p> */}
                </div>
                {imageUrl ? (
                  <div className={styles.foundimagecover}>
                    <img
                      className={styles.postImageMedia11}
                      src={imageUrl}
                      alt="post-img-alt"
                      width="100%"
                      height="auto"
                    />
                  </div>
                ) : null}
                <p className={styles.teambondingtextmainone1}>
                  <MDEditor.Markdown source={blogData.body} />
                </p>
                <div>
                  <p className={styles.shareonsocial}>Share on social</p>
                  <div className={styles.logoflexdisplay}>
                    <div className={styles.logoteambonding}>
                      {instagram.insta}
                    </div>
                    <div className={styles.logoteambonding}>
                      {facebook.fbaook}
                    </div>
                    <div className={styles.logoteambonding}>{linkedin.in}</div>
                    <div className={styles.logoteambonding}>{twitter.twt}</div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
      )}
    </div>
  );
};

export default FoundationPost;
