import completeLogo from "../../assets/images/canaryPointCS_logo.png";
import amfb from "../../assets/images/amfb.png";
import teamBonding from "../../assets/images/teamBonding.png";
import blankImage from "../../assets/images/blankImage.png";
import investmentBanking from "../../assets/img/whatwedo-img/Investment-Banking.jpg";
import assets from "../../assets/images/Assets.png";
import canaryPointLogo from "../../assets/images/canaryPointLogo.png";
import instantCash from "../../assets/images/InstantCash.png";
import MonieSTree from "../../assets/images/MonieSTree.png";
import nairagram from "../../assets/images/Nairagram.png";
import sebastian from "../../assets/images/Sebastian.png";
import swiftandallied from "../../assets/images/Swift&Allied.png";
import patnership from "../../assets/images/patnership.png";
import investmentBankingImg from "../../assets/images/investmentBankingImg.png";
import microFinanceBankingImg from "../../assets/images/microFinanceBankingImg.png";
import remittanceImg from "../../assets/images/remittanceImg.png";
import bdc_Img from "../../assets/images/bdc_Img.png";
import productsImg from "../../assets/img/whatwedo-img/productsImage.jpg";

const Images = {
  completeLogo,
  amfb,
  teamBonding,
  blankImage,
  investmentBanking,
  assets,
  canaryPointLogo,
  instantCash,
  MonieSTree,
  nairagram,
  sebastian,
  swiftandallied,
  patnership,
  investmentBankingImg,
  microFinanceBankingImg,
  remittanceImg,
  bdc_Img,
  productsImg,
};
export default Images;
