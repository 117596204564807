import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Active.css";
import { BASE_URL } from "../../config";

const WhatWeNeed = () => {
  const [active, setActive] = useState(0);
  const [jobData, setJobData] = useState([]);

  const handleActive = (i) => {
    setActive(i);
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  const getAllJobs = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/jobs`);
      const data = response.data;
      setJobData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const responsibilities = jobData.map((job) => job.responsibilities);
  const requirements = jobData.map((job) => job.requirements);

  return (
    <div
      className="interested-border atminimumcontainer"
      style={{ backgroundColor: "white", display: "flex" }}
    >
      <div className="dommy-for-container switchpanel">
        {
          // eslint-disable-next-line
          jobData.map((job, i) => (
            <p
              className={
                active === i
                  ? "job-status-click hover-text-size"
                  : "job-status-click1 hover-text-size"
              }
              onClick={() => handleActive(i)}
            >
              {job.job_title}
            </p>
          ))
        }
      </div>
      <div className="text-jurisdiction-what-we-need">
        {
          // eslint-disable-next-line
          jobData.map((job, i) => {
            if (active === i)
              return (
                <div>
                  <div>
                    <h2 className="headerJob">What We Need</h2>
                    <p className=" responsibiliity">{job.what_we_need}</p>
                  </div>
                  <div>
                    <h2 className="headerJob">Key Objectives / Duties</h2>
                    <p className="responsibiliity">
                      {
                        // eslint-disable-next-line
                        responsibilities.map((resp, i) => {
                          if (active === i) {
                            return resp.split("\n").map((res, index) => (
                              <li key={index} style={{ listStyle: "none" }}>
                                {res}
                              </li>
                            ));
                          }
                        })
                      }
                    </p>
                  </div>
                  <div>
                    <h2 className="headerJob">
                      Required Qualifications, Skills & Experience
                    </h2>
                    <p className="responsibiliity">
                      {
                        // eslint-disable-next-line
                        requirements.map((req, i) => {
                          if (active === i) {
                            return req.split("\n").map((req, index) => (
                              <li key={index} style={{ listStyle: "none" }}>
                                {req}
                              </li>
                            ));
                          }
                        })
                      }
                    </p>
                  </div>
                  <div>
                    <h2 className="headerJob">Location</h2>
                    <p className="responsibiliity">{job.location}</p>
                  </div>
                  <div>
                    <h2 className="headerJob">Application</h2>
                    <p className="responsibiliity">{job.application_method}</p>
                  </div>
                </div>
              );
          })
        }
      </div>
    </div>
  );
};

export default WhatWeNeed;
