import React, { useState } from "react";
import { people } from "./OurPeopleData";
import Container from "@material-ui/core/Container";
import { Modal } from "./Modal";
import "./About.css";

const Management = () => {
  const [showModal, setShowModal] = useState(false);
  const [personId, setPersonId] = useState();

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <div>
      <Container>
        <section className="ourpeoplestakeholdercontainer">
          {people.map((person) => {
            const { name, img, id, title } = person;
            return (
              <article
                key={id}
                id={`${id}`}
                onClick={() => {
                  openModal();
                  setPersonId(person.id);
                }}
                className="article-width flex-article-ourpeople"
                style={{ cursor: "pointer" }}
              >
                <img
                  src={img}
                  alt="management-team"
                  className="our-people-img"
                />
                <div style={{ position: "relative", top: "1rem" }}>
                  <p className="name-styles-stakeholders">{name}</p>
                  <p className="text-center-our-people">{title}</p>
                </div>
              </article>
            );
          })}
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            personId={personId}
            section="people"
          />
        </section>
      </Container>
    </div>
  );
};

export default Management;
