import React, { useState, useEffect } from "react";
import axios from "axios";
import RecentCardMedia from "components/MediaFolder/RecentCardMedia";
import Container from "@material-ui/core/Container";
import styles from "../../components/HomePageFolder/Homepage.module.css";

import { BASE_URL } from "../../config";

const TeamBondingEvent = ({ morerecent }) => {
  const [blogData, setBlogData] = useState([]);
  // eslint-disable-next-line
  const [indexCount, setIndexCount] = useState(3);

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  const getAllBlogPosts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/articles`);
      const data = response.data;
      setBlogData(data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.recentcontainer}>
      <Container>
        <h1 className={styles.morerecentstyle}>{morerecent}</h1>
        <div className={styles.flexcontainerrecentcard}>
          {
            // eslint-disable-next-line
            blogData.map((blog, index) => {
              const imageUrl = BASE_URL + blog.images[0].url;
              if (index < indexCount) {
                console.log(index);
                return (
                  <RecentCardMedia
                    linkTo={`/media/news/${blog.id}`}
                    key={index}
                    id={blog.id}
                    title={blog.title}
                    body={blog.body}
                    image={imageUrl}
                  />
                );
              }
            })
          }
        </div>
      </Container>
    </div>
  );
};

export default TeamBondingEvent;
