import React from "react";
import ProductsImage from "assets/img/whatwedo-img/productsImage.jpg";
import MonieTreeLogo from "assets/img/whatwedo-img/monieTreeImage.png";
import instantCashLogo from "assets/img/whatwedo-img/instantCash.gif";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Grid } from "@material-ui/core";
import whatToDoStyle from "../WhatWeDoStyle";

const useStyles = makeStyles(whatToDoStyle);

const Products = () => {
  const classes = useStyles();

  return (
    <div style={{ height: "100%" }}>
      <Grid className={classes.contentBody5}>
        <div>
          <Grid style={{ marginLeft: "20px", color: "#131747" }}>
            <Typography
              variant="h6"
              style={{ width: "100%", fontFamily: "Gotham Rounded Medium" }}
            >
              <strong>Products</strong>
            </Typography>
          </Grid>
          <Grid container style={{ position: "relative" }}>
            <Grid className={classes.productsUndergroundLayer}></Grid>
            <Grid item className={classes.InvestmentBankingDiv} xs={12}>
              <Grid>
                <img
                  src={ProductsImage}
                  alt="ProductsImage"
                  className={classes.contentBigImg}
                />
              </Grid>
              <Grid item xs={12} md={8} className={classes.contents}>
                <Typography className={classes.contentTypography}>
                  We are constantly innovating to provide the best financial
                  products in the market. Our current product offerings range
                  from loans to savings and payments. The financial market in
                  Africa is largely emerging and we are designing solutions for
                  the next 100 million users.
                </Typography>
                <div style={{ display: "flex" }}>
                  <Grid className={classes.contentSmailImgDiv}>
                    <a
                      target="_blank"
                      href={"https://www.instantcashng.com/"}
                      rel="noreferrer"
                    >
                      <img
                        src={instantCashLogo}
                        alt="InstantCash-Logo"
                        className={classes.contentSmailImage}
                        style={{ marginRight: "2rem" }}
                      />
                    </a>
                  </Grid>
                  <Grid className={classes.contentSmailImgDiv}>
                    <a
                      target="_blank"
                      href={"https://www.monietree.com/"}
                      rel="noreferrer"
                    >
                      <img
                        src={MonieTreeLogo}
                        alt="MonieTree-Logo"
                        className={classes.contentSmailImage}
                      />
                    </a>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default Products;
