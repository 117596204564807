import React, { useState, useEffect } from "react";
import {
  homeSlide,
  listenerCircleHomeslide,
} from "../HomePageFolder/HomeCarouselData";
import Container from "@material-ui/core/Container";
import styles from "../../components/HomePageFolder/Homepage.module.css";
import { useSwipeable } from "react-swipeable";

const HomeCarousel = () => {
  const [active, setActive] = useState(0);
  const handleNext = () => {
    active < homeSlide.length - 1 && setActive(active + 1);
  };
  const handleBack = () => {
    active > 0 && setActive(active - 1);
  };
  const handleIndexClick = (event) => {
    let num = { active: +event.target.dataset.index };
    setActive(num.active);
  };
  useEffect(() => {
    const handleSlide = () => {
      if (active < homeSlide.length - 1) {
        let timeOutId = setTimeout(() => {
          setActive(active + 1);
          clearTimeout(timeOutId);
        }, 15000);
      } else if (active >= homeSlide.length - 1) {
        let timeOutIdTwo = setTimeout(() => {
          setActive(0);
          clearTimeout(timeOutIdTwo);
        }, 15000);
      }
    };
    handleSlide();
  }, [active]);
  const handlers = useSwipeable({
    onSwipedLeft: () => handleNext(),
    onSwipedRight: () => handleBack(),
  });

  return (
    <div className={styles.homecarouselbackground}>
      <Container>
        <div className={styles.ratioaspecthomepage} {...handlers}>
          <div className={styles.homecarouselflexitem}>
            <div className={styles.carouselhomeitemone}>
              <div>{homeSlide[active].text}</div>
              <p className={styles.carouseltexthomeslide}>
                {" "}
                We are constantly innovating to provide the best financial
                solutions in the market.
              </p>
            </div>

            <div className={styles.carouselhomeitetwo}>
              <img
                src={homeSlide[active].imgageslide}
                alt="carousel"
                className={styles.homeslideimg}
              />
              <div className={styles.carousellistenerflex}>
                {" "}
                {listenerCircleHomeslide.map((cirlce, index) => (
                  <div
                    id="circle-event-space"
                    key={index}
                    data-index={index}
                    onClick={handleIndexClick}
                    className={
                      index === active ? styles.active1 : styles.nonactive
                    }
                  >
                    {cirlce}
                  </div>
                ))}{" "}
              </div>
              <section className={styles.prevandNexthanler}>
                <div>
                  <svg
                    className={styles.btnclickbackward}
                    onClick={handleBack}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.33594 0.984375C8.82031 0.34375 10.375 0.0234375 12 0.0234375C13.625 0.0234375 15.1797 0.34375 16.6641 0.984375C18.1484 1.60937 19.4219 2.46094 20.4844 3.53906C21.5469 4.60156 22.3984 5.875 23.0391 7.35938C23.6797 8.82812 24 10.3828 24 12.0234C24 13.6484 23.6797 15.2031 23.0391 16.6875C22.3984 18.1562 21.5469 19.4297 20.4844 20.5078C19.4219 21.5703 18.1484 22.4141 16.6641 23.0391C15.1797 23.6797 13.625 24 12 24C10.375 24 8.82031 23.6797 7.33594 23.0391C5.85156 22.4141 4.57812 21.5703 3.51562 20.5078C2.45312 19.4297 1.60156 18.1562 0.960938 16.6875C0.320312 15.2031 0 13.6484 0 12.0234C0 10.3828 0.320312 8.82812 0.960938 7.35938C1.60156 5.875 2.45312 4.60156 3.51562 3.53906C4.57812 2.46094 5.85156 1.60937 7.33594 0.984375ZM8.03906 12.6094L13.5469 17.7656C13.7188 17.9375 13.9219 18.0234 14.1562 18.0234C14.3906 18.0078 14.5859 17.9141 14.7422 17.7422C14.8984 17.5703 14.9766 17.3672 14.9766 17.1328C14.9609 16.8984 14.8672 16.7031 14.6953 16.5469L9.84375 12L14.6953 7.42969C14.8672 7.27344 14.9609 7.07812 14.9766 6.84375C14.9766 6.60938 14.8984 6.40625 14.7422 6.23438C14.5859 6.0625 14.3906 5.97656 14.1562 5.97656C13.9219 5.96094 13.7188 6.03125 13.5469 6.1875L8.03906 11.3672C7.85156 11.5391 7.75781 11.75 7.75781 12C7.75781 12.2344 7.85156 12.4375 8.03906 12.6094Z"
                      fill="white"
                      fill-opacity="0.617239"
                    />
                  </svg>
                </div>
                <div>
                  <svg
                    className={styles.btnclicknextbtn}
                    onClick={handleNext}
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.6641 0.984375C15.1797 0.34375 13.625 0.0234375 12 0.0234375C10.375 0.0234375 8.82031 0.34375 7.33594 0.984375C5.85156 1.60937 4.57812 2.46094 3.51562 3.53906C2.45312 4.60156 1.60156 5.875 0.960938 7.35938C0.320312 8.82812 0 10.3828 0 12.0234C0 13.6484 0.320312 15.2031 0.960938 16.6875C1.60156 18.1562 2.45312 19.4297 3.51562 20.5078C4.57812 21.5703 5.85156 22.4141 7.33594 23.0391C8.82031 23.6797 10.375 24 12 24C13.625 24 15.1797 23.6797 16.6641 23.0391C18.1484 22.4141 19.4219 21.5703 20.4844 20.5078C21.5469 19.4297 22.3984 18.1562 23.0391 16.6875C23.6797 15.2031 24 13.6484 24 12.0234C24 10.3828 23.6797 8.82812 23.0391 7.35938C22.3984 5.875 21.5469 4.60156 20.4844 3.53906C19.4219 2.46094 18.1484 1.60937 16.6641 0.984375ZM15.9609 12.6094L10.4531 17.7656C10.2812 17.9375 10.0781 18.0234 9.84375 18.0234C9.60938 18.0078 9.41406 17.9141 9.25781 17.7422C9.10156 17.5703 9.02344 17.3672 9.02344 17.1328C9.03906 16.8984 9.13281 16.7031 9.30469 16.5469L14.1562 12L9.30469 7.42969C9.13281 7.27344 9.03906 7.07812 9.02344 6.84375C9.02344 6.60938 9.10156 6.40625 9.25781 6.23438C9.41406 6.0625 9.60938 5.97656 9.84375 5.97656C10.0781 5.96094 10.2812 6.03125 10.4531 6.1875L15.9609 11.3672C16.1484 11.5391 16.2422 11.75 16.2422 12C16.2422 12.2344 16.1484 12.4375 15.9609 12.6094Z"
                      fill="white"
                      fill-opacity="0.617239"
                    />
                  </svg>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
export default HomeCarousel;
