import caroimg from "../../assets/images/Carouselimg.png";
import Carouselimgthree from "../../assets/images/Carouselimgthree.png";
import Carouselimgtwo from "../../assets/images/Carouselimgtwo.png";
export const carouselData = [
  {
    name: "Gold Zena",
    title: "Digital Marketer",
    id: 1,
    img: caroimg,
    text:
      "“I’ve gotten all the support I need to excel at my job. The best part of my work experience is how receptive management is to receiving feedback and suggestions based on my expertise, it’s rare to see such flexibility in a financial instituition.”",
  },

  {
    name: "Susan Okolo",
    title: "HR Generalist",
    id: 2,
    img: Carouselimgtwo,
    text:
      "“Canary is family, it’s fun to resume to warm smiles and happy faces on a daily. I love being part of something bigger that makes a difference. I love that everyone cares and that you have flexibility when needed.”",
  },
  {
    name: "Abiodun Babalola",
    title: "Operations Manager",
    id: 3,
    img: Carouselimgthree,
    text:
      "“Joining Canary Point Colony was one of the ‘right step in the right direction’ moves I have made in my career. The level of achieveable growth is immeasurable; there are lots of amazing minds here, I just listen, learn, understand, and replicate.”",
  },
];

export const listenerCircle = [<div></div>, <div></div>, <div></div>];
