import React, { useLayoutEffect } from "react";
import "./Privacy.css";

const Privacy = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="privacy_container">
      <h1 className="privacy_header_lg">Privacy Policy</h1>
      <div className="privacy_main_container">
        <p className="privacy_content">Last Updated: 4th Mar, 2020.</p>
        <p className="privacy_content">
          Canary Point Corporate Services respects your privacy and is committed
          to protecting your personal data. This privacy policy will inform you
          as to how we look after your personal data when you visit our website
          (regardless of where you visit it from) and tell you about your
          privacy rights and how the law protects you. By using or accessing our
          websites or by providing personal information to us on or through
          these websites, you consent to the collection, use, transfer and
          disclosure of that information in accordance with this privacy policy
          and applicable law. Please also use the Glossary to understand the
          meaning of some of the terms used in this privacy policy.
        </p>
        <div className="privacy_section">
          <h2 className="privacy_header_md">
            1. Important Information and who we are
          </h2>
          <p className="privacy_content">
            Canary Point Corporate Services is a financial services group of
            company. It is a holding company for various other companies that
            offer a range of investment and financial services to the public
            with the aim of offering effective money management.
          </p>
          <h2 className="privacy_header_sm">
            1.1 Purpose of this Privacy policy
          </h2>
          <p className="privacy_content">
            This privacy policy aims to give you information on how Canary Point
            Corporate Services collects and processes your personal data through
            your use of this website, including any data you may provide through
            this website when you purchase a product or service OR take part in
            a competition OR sign up to a newsletter. This website is not
            intended for children and we do not knowingly collect data relating
            to children. If we are informed that we have collected any personal
            information from children under the age of 18, and are asked to
            delete such information from our system, we will promptly do so. It
            is important that you read this privacy policy together with any
            other privacy policy or fair processing policy we may provide on
            specific occasions when we are collecting or processing personal
            data about you so that you are fully aware of how and why we are
            using your data. This privacy policy supplements other notices and
            privacy policies and is not intended to override them.
          </p>
          <h2 className="privacy_header_sm">1.2 Controller</h2>
          <p className="privacy_content">
            Canary Point Corporate Services is the controller and responsible
            for your personal data (collectively referred to as ”Canary Point
            Corporate Service”, “we”, “us” or “our” in this privacy policy). We
            have appointed a Data Protection Officer (DPO) who is responsible
            for overseeing questions in relation to this privacy policy. If you
            have any questions about this privacy policy, including any requests
            to exercise your legal rights, please contact the DPO using the
            details set out in the contact section below.
          </p>
          <h2 className="privacy_header_sm">
            1.3 Changes to the Privacy Policy and your duty to inform us of
            changes
          </h2>
          <p className="privacy_content">
            We keep our privacy policy under regular review. We may amend this
            privacy policy at any time by posting the amended terms on our
            Website. We may or may not post notices on the homepage of our
            Website when such changes occur. We may make improvements and/or
            changes to the Website which include adding or removing features, or
            terminating the Website at any time without notice. We (a) reserve
            the right (but have no obligation) to change the Content or other
            offerings on the Website, at any time without any notice or
            liability to you or any other person; and (b) do not warrant that
            information on the Websites is accurate, complete, reliable, current
            or error-free. Some jurisdictions may not allow the exclusions and
            disclaimers of certain implied warranties, so some of the provisions
            of this section may not apply to you. It is important that the
            personal data we hold about you is accurate and current. Please keep
            us informed if your personal data changes during your relationship
            with us.
          </p>
          <h2 className="privacy_header_sm">1.4 Third-party Links</h2>
          <p className="privacy_content">
            This website may include links to third-party websites, plug-ins and
            applications. Clicking on those links or enabling those connections
            may allow third parties to collect or share data about you. If you
            choose to link to an external website from our Website, you will
            leave our Website. We do not control these third-party websites and
            are not responsible for their Privacy Policy. We encourage you to
            read the privacy policy of such parties or websites including their
            terms and conditions of use.
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">
            2. The data we collect about you
          </h2>
          <p className="privacy_content">
            Personal data, or personal information, means any information about
            an individual from which that person can be identified. It does not
            include data where the identity has been removed (anonymous data).
            We may collect, use, store and transfer different kinds of personal
            data about you which we have grouped together as follows:
          </p>

          <p style={{ marginLeft: 40 }} className="privacy_content">
            <li style={{ listStyleType: "decimal" }}>
              Identity Data includes your first name, maiden name, last name,
              username or similar identifier, marital status, title, date of
              birth and gender.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Contact Data includes your billing address, delivery address,
              email address and telephone numbers.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Financial Data includes your bank account and payment card
              details.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Transaction Data includes details about payments to and from you
              and other details of products and services you have purchased from
              us.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Technical Data includes your internet protocol (IP) address, your
              login data, browser type and version, time zone setting and
              location, browser plug-in types and versions, operating system and
              platform, and other technology on the devices you use to access
              this website.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Profile Data includes your username and password, purchases or
              orders made by you, your interests, preferences, feedback and
              survey responses.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Usage Data includes information about how you use our website,
              products and services.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Marketing and Communications Data includes your preferences in
              receiving marketing from us and our third parties and your
              communication preferences.
            </li>
          </p>
          <p className="privacy_content">
            We also collect, use and share Aggregated Data such as statistical
            or demographic data for any purpose. Aggregated Data could be
            derived from your personal data but is not considered personal data
            in law as this data will not directly or indirectly reveal your
            identity. For example, we may aggregate your Usage Data to calculate
            the percentage of users accessing a specific website feature.
            However, if we combine or connect Aggregated Data with your personal
            data so that it can directly or indirectly identify you, we treat
            the combined data as personal data which will be used in accordance
            with this privacy policy.
            <br /> We do not collect any Special Categories of Personal Data
            about you (this includes details about your race or ethnicity,
            religious or philosophical beliefs, sex life, sexual orientation,
            political opinions, trade union membership, information about your
            health, and genetic and biometric data). Nor do we collect any
            information about criminal convictions and offences.
          </p>
          <h2 className="privacy_header_sm">
            2.1 If you fail to provide personal data
          </h2>
          <p className="privacy_content">
            Where we need to collect personal data by law, or under the terms of
            a contract we have with you, and you fail to provide that data when
            requested, we may not be able to perform the contract we have or are
            trying to enter into with you (for example, to provide you with
            money transfer services). In this case, we may have to cancel a
            product or service you have with us but we will notify you if this
            is the case at the time.
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">
            3. How is your personal data collected and verified
          </h2>
          <p className="privacy_content">
            We use different methods to collect data from and about you
            including through:
          </p>
          <p style={{ marginLeft: 40 }} className="privacy_content">
            <li style={{ listStyleType: "decimal" }}>
              Direct interactions. You may give us your identity, contact and
              financial data by filling in forms or by corresponding with us by
              post, phone, video call, email or otherwise. This includes
              personal data you provide when you:
              <p style={{ marginLeft: 30 }} className="privacy_content">
                <li style={{ listStyleType: "decimal" }}>
                  apply for our products or services;
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  create an account on our website;
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  subscribe to our service or publications;
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  request marketing to be sent to you;
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  enter a competition, promotion or survey; or
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  give us feedback or contact us.
                </li>
              </p>
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Automated technologies or interactions. As you interact with our
              website, we will automatically collect Technical Data about your
              equipment, browsing actions and patterns. We collect this personal
              data by using cookies and other similar technologies.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Third parties or publicly available sources. We will receive
              personal data about you from various third parties and public
              sources as set out below:
              <p style={{ marginLeft: 30 }} className="privacy_content">
                <li style={{ listStyleType: "decimal" }}>
                  Technical Data from the following parties:
                  <p style={{ marginLeft: 30 }} className="privacy_content">
                    <li style={{ listStyleType: "decimal" }}>
                      analytics providers;
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                      advertising networks; and
                    </li>
                    <li style={{ listStyleType: "decimal" }}>
                      search information providers.
                    </li>
                  </p>
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Contact, Financial and Transaction Data from providers of
                  technical, payment and delivery services.
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Identity and Contact Data from data brokers or aggregators.
                </li>
                <li style={{ listStyleType: "decimal" }}>
                  Identity and Contact Data from publicly available sources.
                </li>
              </p>
            </li>
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">
            4. How we use your personal data
          </h2>
          <p className="privacy_content">
            We will only use your personal data when the law allows us to. Most
            commonly, we will use your personal data in the following
            circumstances:
          </p>
          <p style={{ marginLeft: 40 }} className="privacy_content">
            <li style={{ listStyleType: "decimal" }}>
              Where we need to perform the contract we are about to enter into
              or have entered into with you.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Where it is necessary for our legitimate interests (or those of a
              third party) and your interests and fundamental rights do not
              override those interests.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Where we need to comply with a legal obligation.
            </li>
          </p>
          <p className="privacy_content">
            Generally, we do not rely on consent as a legal basis for processing
            your personal data although we will get your consent before sending
            third party direct marketing communications to you via email or text
            message. You have the right to withdraw consent to marketing at any
            time by contacting us.
          </p>
          <h2 className="privacy_header_sm">
            4.1 Purposes for which we will use your personal data
          </h2>
          <p className="privacy_content">
            We have set out below, in a table format, a description of all the
            ways we plan to use your personal data, and which of the legal bases
            we rely on to do so. We have also identified what our legitimate
            interests are where appropriate. Note that we may process your
            personal data for more than one lawful ground depending on the
            specific purpose for which we are using your data. Please contact us
            if you need details about the specific legal ground we are relying
            on to process your personal data where more than one ground has been
            set out in the table below.
          </p>
          <div style={{ overflowX: "auto" }}>
            <table>
              <thead>
                <tr>
                  <td>Purpose/Activity</td>
                  <td>Type of data</td>
                  <td>
                    Lawful basis for processing including basis of legitimate
                    interest
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="privacy_content">
                    To register you as a new customer
                  </td>
                  <td className="privacy_content">
                    (a)Identity
                    <br /> (b)Contact
                  </td>
                  <td className="privacy_content">
                    (a) Performance of a contract with you
                  </td>
                </tr>
                <tr>
                  <td className="privacy_content">
                    To process and deliver your order including:
                    <br />
                    (a) Manage payments, fees and charges
                    <br /> (b) Collect and recover money owed to us
                  </td>
                  <td className="privacy_content">
                    (a) Identity
                    <br />
                    (b) Contact <br />
                    (c) Financial
                    <br />
                    (d) Transaction <br />
                    (e) Marketing and Communications
                  </td>
                  <td className="privacy_content">
                    (a) Performance of a contract with you <br />
                    (b) Necessary for our legitimate interests (to recover debts
                    due to us)
                  </td>
                </tr>
                <tr>
                  <td className="privacy_content">
                    To manage our relationship with you which will include:
                    <br />
                    (a) Notifying you about changes to our terms or privacy
                    policy
                    <br /> (b) Asking you to leave a review or take a survey
                  </td>
                  <td className="privacy_content">
                    (a) Identity
                    <br />
                    (b) Contact <br />
                    (c) Profile
                    <br />
                    (d) Marketing and Communications
                  </td>
                  <td className="privacy_content">
                    (a) Performance of a contract with you <br />
                    (b) Necessary to comply with a legal obligation <br />
                    (c) Necessary for our legitimate interests (to keep our
                    records updated and to study how customers use our
                    products/services)
                  </td>
                </tr>
                <tr>
                  <td className="privacy_content">
                    To enable you to partake in a prize draw, competition or
                    complete a survey
                  </td>
                  <td className="privacy_content">
                    (a) Identity
                    <br />
                    (b) Contact <br />
                    (c) Profile
                    <br />
                    (d) Usage
                    <br />
                    (e) Marketing and Communications
                  </td>
                  <td className="privacy_content">
                    (a) Performance of a contract with you <br />
                    (b) Necessary for our legitimate interests (to study how
                    customers use our products/services, to develop them and
                    grow our business)
                  </td>
                </tr>
                <tr>
                  <td className="privacy_content">
                    To administer and protect our business and this website
                    (including troubleshooting, data analysis, testing, system
                    maintenance, support, reporting and hosting of data)
                  </td>
                  <td className="privacy_content">
                    (a) Identity
                    <br />
                    (b) Contact <br />
                    (c) Technical
                  </td>
                  <td className="privacy_content">
                    (a) Necessary for our legitimate interests (for running our
                    business, provision of administration and IT services,
                    network security, to prevent fraud and in the context of a
                    business reorganisation or group restructuring exercise)
                    <br />
                    (b) Necessary to comply with a legal obligation
                  </td>
                </tr>
                <tr>
                  <td className="privacy_content">
                    To deliver relevant website content and advertisements to
                    you and measure or understand the effectiveness of the
                    advertising we serve to you
                  </td>
                  <td className="privacy_content">
                    (a) Identity
                    <br />
                    (b) Contact <br />
                    (c) Profile
                    <br />
                    (d) Usage
                    <br />
                    (e) Marketing and Communications
                    <br /> (f) Technical
                  </td>
                  <td className="privacy_content">
                    Necessary for our legitimate interests (to study how
                    customers use our products/services, to develop them, to
                    grow our business and to inform our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td className="privacy_content">
                    To use data analytics to improve our website,
                    products/services, marketing, customer relationships and
                    experiences
                  </td>
                  <td className="privacy_content">
                    (a) Technical
                    <br />
                    (b) Usage
                    <br />
                  </td>
                  <td className="privacy_content">
                    Necessary for our legitimate interests (to define types of
                    customers for our products and services, to keep our website
                    updated and relevant, to develop our business and to inform
                    our marketing strategy)
                  </td>
                </tr>
                <tr>
                  <td className="privacy_content">
                    To make suggestions and recommendations to you about goods
                    or services that may be of interest to you
                  </td>
                  <td className="privacy_content">
                    (a) Identity
                    <br />
                    (b) Contact <br />
                    (c) Technical
                    <br />
                    (d) Usage
                    <br />
                    (e) Profile
                    <br /> (f) Marketing and Communications
                  </td>
                  <td className="privacy_content">
                    Necessary for our legitimate interests (to develop our
                    products/services and grow our business)
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 className="privacy_header_sm">4.1.1 Marketing</h3>
          <p className="privacy_content">
            We strive to provide you with choices regarding certain personal
            data uses, particularly around marketing and advertising.
          </p>
          <h3 className="privacy_header_sm">
            4.1.2 Promotional offers from Us
          </h3>
          <p className="privacy_content">
            We may use your Identity, Contact, Technical, Usage and Profile Data
            to form a view on what we think you may want or need, or what may be
            of interest to you. This is how we decide which products, services
            and offers may be relevant for you (we call this marketing).
            <br />
            You will receive marketing communications from us if you have
            requested information from us or purchased services from us and you
            have not opted out of receiving that marketing.
          </p>
          <h3 className="privacy_header_sm">4.1.3 Third-party Marketing</h3>
          <p className="privacy_content">
            We will get your express opt-in consent before we share your
            personal data with any third party for marketing purposes.
          </p>
          <h3 className="privacy_header_sm">4.1.4 Opting Out</h3>
          <p className="privacy_content">
            You can ask us or third parties to stop sending you marketing
            messages at any time by contacting us at any time.
            <br /> Where you opt out of receiving these marketing messages, this
            will not apply to personal data provided to us as a result of a
            product/service purchase, warranty registration, product/service
            experience or other transactions.
          </p>
          <h3 className="privacy_header_sm">4.1.5 Cookies</h3>
          <p className="privacy_content">
            You can set your browser to refuse all or some browser cookies, or
            to alert you when websites set or access cookies. If you disable or
            refuse cookies, please note that some parts of this website may
            become inaccessible or not function properly.
          </p>
          <h3 className="privacy_header_sm">4.1.6 Change of Purpose</h3>
          <p className="privacy_content">
            We will only use your personal data for the purposes for which we
            collected it, unless we reasonably consider that we need to use it
            for another reason and that reason is compatible with the original
            purpose. If you wish to get an explanation as to how the processing
            for the new purpose is compatible with the original purpose, please
            contact us.
            <br /> If we need to use your personal data for an unrelated
            purpose, we will notify you and we will explain the legal basis
            which allows us to do so. Please note that we may process your
            personal data without your knowledge or consent, in compliance with
            the above rules, where this is required or permitted by law.
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">
            5. Disclosure of your personal data
          </h2>
          <p className="privacy_content">
            We require all third parties to respect the security of your
            personal data and to treat it in accordance with the law. We do not
            allow our third-party service providers to use your personal data
            for their own purposes and only permit them to process your personal
            data for specified purposes and in accordance with our instructions.
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">6. Data Security</h2>
          <p className="privacy_content">
            We have put in place appropriate security measures to prevent your
            personal data from being accidentally lost, used or accessed in an
            unauthorised way, altered or disclosed. In addition, we limit access
            to your personal data to those employees, agents, contractors and
            other third parties who have a business need to know.
            <br />
            They will only process your personal data on our instructions and
            they are subject to a duty of confidentiality.
            <br /> We have put in place procedures to deal with any suspected
            personal data breach and will notify you and any applicable
            regulator of a breach where we are legally required to do so.
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">7. Data Retention</h2>
          <p className="privacy_content">
            How long will you use my personal data for? <br />
            We will only retain your personal data for as long as reasonably
            necessary to fulfil the purposes we collected it for, including for
            the purposes of satisfying any legal, regulatory, tax, accounting or
            reporting requirements. We may retain your personal data for a
            longer period in the event of a complaint or if we reasonably
            believe there is a prospect of litigation in respect to our
            relationship with you.
            <br /> To determine the appropriate retention period for personal
            data, we consider the amount, nature and sensitivity of the personal
            data, the potential risk of harm from unauthorised use or disclosure
            of your personal data, the purposes for which we process your
            personal data and whether we can achieve those purposes through
            other means, and the applicable legal, regulatory, tax, accounting
            or other requirements.
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">8. Your Legal Rights</h2>
          <p className="privacy_content">
            Under certain circumstances, you have rights under data protection
            laws in relation to your personal data. You have the right to:
          </p>
          <p style={{ marginLeft: 40 }} className="privacy_content">
            <li style={{ listStyleType: "decimal" }}>
              Request access to your personal data (commonly known as a “data
              subject access request”). This enables you to receive a copy of
              the personal data we hold about you and to check that we are
              lawfully processing it.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Request correction of the personal data that we hold about you.
              This enables you to have any incomplete or inaccurate data we hold
              about you corrected, though we may need to verify the accuracy of
              the new data you provide to us.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Request erasure of your personal data. This enables you to ask us
              to delete or remove personal data where there is no good reason
              for us continuing to process it. You also have the right to ask us
              to delete or remove your personal data where you have successfully
              exercised your right to object to processing (see below), where we
              may have processed your information unlawfully or where we are
              required to erase your personal data to comply with local law.
              Note, however, that we may not always be able to comply with your
              request of erasure for specific legal reasons which will be
              notified to you, if applicable, at the time of your request.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Object to processing of your personal data where we are relying on
              a legitimate interest (or those of a third party) and there is
              something about your particular situation which makes you want to
              object to processing on this ground as you feel it impacts on your
              fundamental rights and freedoms.You also have the right to object
              where we are processing your personal data for direct marketing
              purposes. In some cases, we may demonstrate that we have
              compelling legitimate grounds to processyour information which
              override your rights and freedoms.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Request restriction of processing of your personal data. This
              enables you to ask us to suspend the processing of your personal
              data in the following scenarios:
            </li>
            <p style={{ marginLeft: 30 }} className="privacy_content">
              <li style={{ listStyleType: "decimal" }}>
                If you want us to establish the data’s accuracy.
              </li>
              <li style={{ listStyleType: "decimal" }}>
                Where our use of the data is unlawful but you do not want us to
                erase it.
              </li>
              <li style={{ listStyleType: "decimal" }}>
                Where you need us to hold the data even if we no longer require
                it as you need it to establish, exercise or defend legal claims.
              </li>
              <li style={{ listStyleType: "decimal" }}>
                You have objected to our use of your data but we need to verify
                whether we have overriding legitimate grounds to use it.
              </li>
            </p>
            <li style={{ listStyleType: "decimal" }}>
              Request the transfer of your personal data to you or to a third
              party. We will provide to you, or a third party you have chosen,
              your personal data in a structured, commonly used,
              machine-readable format. Note that this right only applies to
              automated information which you initially provided consent for us
              to use or where we used the information to perform a contract with
              you.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Withdraw consent at any time where we are relying on consent to
              process your personal data However, this will not affect the
              lawfulness of any processing carried out before you withdraw your
              consent. If you withdraw your consent, we may not be able to
              provide certain products or services to you. We will advise you if
              this is the case at the time you withdraw your consent.
            </li>
          </p>
          <p className="privacy_content">
            If you wish to exercise any of the rights set out above, please
            contact us.
          </p>
          <h2 className="privacy_header_sm">8.1 No fee usually required</h2>
          <p className="privacy_content">
            You will not have to pay a fee to access your personal data (or to
            exercise any of the other rights). However, we may charge a
            reasonable fee if your request is clearly unfounded, repetitive or
            excessive. Alternatively, we could refuse to comply with your
            request in these circumstances.
          </p>
          <h2 className="privacy_header_sm">8.2 What we may need from you</h2>
          <p className="privacy_content">
            We may need to request specific information from you to help us
            confirm your identity and ensure your right to access your personal
            data or to exercise any of your other rights. This is a security
            measure to ensure that personal data is not disclosed to any person
            who has no right to receive it. We may also contact you to ask you
            for further information in relation to your request to speed up our
            response.
          </p>
          <h2 className="privacy_header_sm">8.3 Time limit to respond</h2>
          <p className="privacy_content">
            We try to respond to all legitimate requests within one month.
            Occasionally it could take us longer than a month if your request is
            particularly complex or you have made a number of requests. In this
            case, we will notify you and keep you updated.
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">9. Glossary</h2>
          <h2 className="privacy_header_sm">9.1 Lawful Basis</h2>
          <p style={{ marginLeft: 30 }} className="privacy_content">
            <li style={{ listStyleType: "decimal" }}>
              Legitimate Interest means the interest of our business in
              conducting and managing our business to enable us to give you the
              best service/product and the best and most secure experience. We
              make sure we consider and balance any potential impact on you
              (both positive and negative) and your rights before we process
              your personaldata for our legitimate interests. We do not use your
              personal data for activities where our interests are overridden by
              the impact on you (unless we have your consent or are otherwise
              required or permitted to by law). You can obtain further
              information about how we assess our legitimate interests against
              any potential impact on you in respect of specific activities by
              contacting us.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Performance of Contract means processing your data where it is
              necessary for the performance of a contract to which you are a
              party or to take steps at your request before entering into such a
              contract.
            </li>
            <li style={{ listStyleType: "decimal" }}>
              Comply with a legal obligation means processing your personal data
              where it is necessary for compliance with a legal obligation that
              we are subject to.
            </li>
          </p>
        </div>
        <div className="privacy_section">
          <h2 className="privacy_header_md">10. Contact Details</h2>
          <p className="privacy_content">
            If you have any questions about this privacy policy or our privacy
            practices, please contact our DPO in the following ways:
            <br /> Email: info@canarypointcs.com <br />
            Address: 17a Dele Adedeji Street, Lekki phase 1.
          </p>
          <p className="privacy_content">
            Canary Point Corporate Services is a financial services group of
            company duly registered with the Corporate Affairs Commission
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
