import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import Container from "@material-ui/core/Container";
import styles from "../../components/MediaFolder/Media.module.css";
import { linkedin, facebook, instagram, twitter } from "./Logo";
import TeamBondingEvent from "./TeamBondingEvent";
import HomeNewsLetter from "components/HomePageFolder/HomeNewsLetter";
import MediaBanner from "components/MediaFolder/MediaBanner";
import MediaNav from "./MediaNav";
import { useParams } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { BASE_URL } from "../../config";

const MediaGallery = ({ history }) => {
  const [blogData, setBlogData] = useState([]);
  // eslint-disable-next-line
  const [blogResult, setBlogResult] = useState([]);

  const { galleryId } = useParams();

  const currentPathNav = history.location.pathname
    .replace("/media/", "")
    .replace("/" + galleryId, "");

  let path;

  useEffect(() => {
    getAllBlogPosts();
  }, []);

  useEffect(() => {
    window.scroll(0, 590);

    const getBlogPost = async () => {
      if (currentPathNav === "blog") {
        // eslint-disable-next-line
        path = "blogs";
      } else if (currentPathNav === "news") {
        path = "articles";
      } else if (currentPathNav === "events") {
        path = "events";
      } else if (currentPathNav === "gallery") {
        path = "galleries";
      }

      try {
        const response = await axios.get(`${BASE_URL}/${path}/${galleryId}`);
        const data = await response.data;
        setBlogData(data);
      } catch (error) {
        console.log(error.message);
      }
    };
    getBlogPost();
  }, [galleryId]);

  const getAllBlogPosts = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/blogs`);
      const data = response.data;
      setBlogResult(data);
    } catch (error) {
      console.log(error);
    }
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const slideImages =
    blogData.images && blogData.images.map((image) => BASE_URL + image.url);

  // const currentPath = history.location.pathname.replace("/media/", "");
  // const currentPathNavIndex = history.location.pathname.lastIndexOf("/");

  return (
    <div>
      <MediaBanner
        s_active={capitalizeFirstLetter(currentPathNav)}
        active={blogData.title}
      />
      <MediaNav active={currentPathNav} />
      <Container style={{ marginTop: 50 }}>
        <div className={styles.teamBondingflex}>
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "50px",
              justifyContent: "space-between",
            }}
          >
            <p className={styles.headerTextMedia2}>{blogData.title}</p>
            <p className={styles.bondingDate}>
              {moment(blogData.published_at).format("Do MMMM, YYYY")}
            </p>
          </div>
          <AliceCarousel autoPlay autoPlayInterval="3000">
            {blogData.images &&
              slideImages.map((blogImage, i) => (
                <div
                  key={i}
                  className="each-slide"
                  style={{
                    background: blogData.images && `url(${blogImage})`,
                    height: "0",
                    width: "100%",
                    backgroundSize: "cover",
                    paddingTop: "56%",
                    borderRadius: "4px",
                  }}
                ></div>
              ))}
          </AliceCarousel>
        </div>
        <div className={styles.teammajorcontent1}>
          <div>
            <p className={styles.shareonsocial}>Share on social</p>
            <div className={styles.logoflexdisplay}>
              <div className={styles.logoteambonding}>{instagram.insta}</div>
              <div className={styles.logoteambonding}>{facebook.fbaook}</div>
              <div className={styles.logoteambonding}>{linkedin.in}</div>
              <div className={styles.logoteambonding}>{twitter.twt}</div>
            </div>
          </div>
        </div>
      </Container>
      <TeamBondingEvent />
      <HomeNewsLetter />
    </div>
  );
};

export default MediaGallery;
