import React, { useState, useEffect } from "react";
import styles from "components/MediaFolder/Media.module.css";
import Container from "@material-ui/core/Container";
import { useHistory } from "react-router-dom";

const MediaNav = ({ active, location }) => {
  const [activeNav, setActiveNav] = useState("recent");

  useEffect(() => {
    setActiveNav(active);
  }, [active]);
  let history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    const { id } = event.target;
    if (id) {
      if (id === "recentmedia") {
        history.push("/media");
      } else if (id === "blogmedia") {
        history.push("/media/blog");
      } else if (id === "blognews") {
        history.push("/media/news");
      } else if (id === "blogevents") {
        history.push("/media/events");
      } else if (id === "bloggallery") {
        history.push("/media/gallery");
      }
    }
  }
  return (
    <div className={styles.mediaNav}>
      <Container>
        <div className={styles.mediaNavflexitems}>
          <p
            onClick={
              (() => {
                setActiveNav("recent");
              },
              handleClick)
            }
            id="recentmedia"
            className={activeNav === "recent" ? "tabactivemedia" : "recent"}
          >
            Recent
          </p>

          <p
            id="blogmedia"
            onClick={
              (() => {
                setActiveNav("blog");
              },
              handleClick)
            }
            className={activeNav === "blog" ? "tabactivemedia" : "blog"}
          >
            Blog
          </p>

          <p
            id="blognews"
            onClick={
              (() => {
                setActiveNav("news");
              },
              handleClick)
            }
            className={activeNav === "news" ? "tabactivemedia" : "news"}
          >
            News
          </p>

          <p
            id="blogevents"
            onClick={
              (() => {
                setActiveNav("events");
              },
              handleClick)
            }
            className={activeNav === "events" ? "tabactivemedia" : "events"}
          >
            Events
          </p>

          <p
            id="bloggallery"
            onClick={
              (() => {
                setActiveNav("gallery");
              },
              handleClick)
            }
            className={activeNav === "gallery" ? "tabactivemedia" : "gallery"}
          >
            Gallery
          </p>
        </div>
      </Container>
    </div>
  );
};

export default MediaNav;
