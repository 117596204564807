import image from "assets/img/whatwedo-img/adeolu-eletu.jpg";
import GothamRounded2 from "assets/font/GothamRounded-Medium.otf";
import Calibri from "assets/font/calibri-regular.ttf";

const WhatWeDoLogo = (theme) => ({
  root: {
    flexGrow: 1,
    color: "#6E6E6E",
    height: "100%",
    fontWeight: 400,
  },
  headerBackgroungImg: {
    position: "relative",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "15rem",
    backgroundImage: `url(${image})`,
  },
  headerTextBox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    background: "transparent",
  },
  headerHeadline: {
    lineHeight: "23px",
    letterSpacing: "o.33px",
    color: "#FFFFFF",
    borderTop: "none",
    fontSize: "2.8rem",
    fontWeight: 600,
    fontFamily: "Gotham Rounded Medium",
    src: `
      url(${GothamRounded2}) format('otf')
    `,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.7rem",
    },
  },
  headlineContentGrid: {
    background: "#4F88C7",
    backgroundColor: "#4F88C7",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: "3rem auto 0",
  },
  headlineContentTypo: {
    fontSize: "24px",
    fontWeight: 400,
    lineHeight: 1.2,
    letterSpacing: 0.4,
    padding: "3rem",
    color: "#ffffff",
    textAlign: "center",
    fontFamily: "Calibri",
    src: `
      url(${Calibri}) format('ttf')
    `,
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      padding: "2rem",
      textAlign: "center",
    },
  },
  undergroundLayer: {
    width: "250px",
    height: "140px",
    backgroundColor: "#F6A71B",
    background: "#F6A71B",
    borderRadius: "25px",
  },
  MicrofinanceUndergroundLayer: {
    position: "absolute",
    top: "29px",
    right: "0px",
    width: "280px",
    height: "140px",
    backgroundColor: "#20B8EB",
    background: "#20B8EB",
    borderRadius: "25px",
  },
  BureauUndergroundLayer: {
    position: "absolute",
    top: "29px",
    right: "0px",
    width: "250px",
    height: "140px",
    backgroundColor: "#E06C26",
    background: "#E06C26",
    borderRadius: "25px",
  },
  remittanceUndergroundLayer: {
    width: "160px",
    height: "100px",
    backgroundColor: "#2D3690",
    background: "#2D3690",
    borderRadius: "25px",
  },
  productsUndergroundLayer: {
    width: "150px",
    height: "100px",
    backgroundColor: "#4F88C7",
    background: "#4F88C7",
    borderRadius: "25px",
  },
  contentBodyGrid: {
    margin: "3rem auto",
    [theme.breakpoints.down("sm")]: {},
    "@media (min-width:600px)": {
      margin: "3rem auto",
      width: "90%",
    },
  },
  contentBody: {
    position: "relative",
    height: "350px",
    [theme.breakpoints.down("xs")]: {
      height: "712px",
    },
    [theme.breakpoints.up("sm")]: {},
    "@media (min-width:600px)": {
      height: "394px",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "row",
      height: "480px",
    },
    [theme.breakpoints.only("lg")]: {
      flexDirection: "row",
      height: "350px",
    },
    [theme.breakpoints.between("600", "750")]: {
      height: "506px",
    },
  },
  contentBody2: {
    position: "relative",
    height: "327px",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "569px",
      marginTop: "2rem",
    },
    [theme.breakpoints.only("sm")]: {
      height: "290px",
    },
    "@media (min-width:600px)": {
      height: "290px",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "row",
      height: "321px",
      marginTop: "2rem",
    },
    [theme.breakpoints.only("lg")]: {
      flexDirection: "row",
      height: "327px",
    },
    [theme.breakpoints.between("520", "578")]: {
      height: "640px",
    },
    [theme.breakpoints.between("578", "600")]: {
      height: "640px",
    },
    [theme.breakpoints.between("600", "750")]: {
      height: "346px",
    },
  },
  contentBody3: {
    position: "relative",
    height: "432px",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "820px",
      marginTop: "2rem",
    },
    [theme.breakpoints.up("sm")]: {
      height: "477px",
    },
    "@media (min-width:600px)": {
      height: "473px",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "row",
      height: "561px",
      marginTop: "2rem",
    },
    [theme.breakpoints.only("lg")]: {
      flexDirection: "row",
      height: "432px",
    },
    [theme.breakpoints.between("600", "750")]: {
      height: "647px",
    },
  },
  contentBody4: {
    position: "relative",
    height: "370px",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "725px",
      marginTop: "2rem",
    },
    [theme.breakpoints.only("sm")]: {
      height: "415px",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "row",
      height: "471px",
      marginTop: "2rem",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
      height: "370px",
      marginTop: "2rem",
    },
    [theme.breakpoints.between("600", "750")]: {
      height: "498px",
    },
  },
  contentBody5: {
    position: "relative",
    height: "334px",
    marginTop: "2rem",
    [theme.breakpoints.down("xs")]: {
      height: "628px",
      marginTop: "2rem",
      zIndex: "1000",
    },
    [theme.breakpoints.up("sm")]: {
      height: "343px",
      zIndex: "1000",
    },
    "@media (min-width:600px)": {
      height: "343px",
      zIndex: "1000",
    },
    [theme.breakpoints.only("md")]: {
      flexDirection: "row",
      height: "387px",
      marginTop: "2rem",
    },
    [theme.breakpoints.only("lg")]: {
      flexDirection: "row",
      height: "334px",
      marginTop: "2rem",
    },
  },
  InvestmentBankingDiv: {
    border: "2px solid #4F88C7",
    position: "absolute",
    top: "20px",
    left: "20px",
    display: "flex",
    flexDirection: "column",
    flex: "1",
    padding: "0",
    backgroundColor: "#ffffff",
    "@media (min-width:600px)": {
      flexDirection: "row",
      padding: "15px",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      width: "70%",
    },
  },
  InvestmentBankingDiv2: {
    border: "2px solid #4F88C7",
    position: "absolute",
    top: "45px",
    right: "25px",
    display: "flex",
    flexDirection: "column",
    padding: "0",
    backgroundColor: "#ffffff",
    "@media (min-width:600px)": {
      flexDirection: "row",
      padding: "15px",
    },
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      width: "70%",
    },
  },
  contentBigImg: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    "@media (min-width:600px)": {},
    [theme.breakpoints.down("md")]: {
      padding: "10px 16px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "300px",
    },
  },
  contents: {
    paddingLeft: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
    },
  },
  contentTypography: {
    textAlign: "left",
    fontSize: "19px",
    fontWeight: 400,
    color: "#6E6E6E",
    lineHeight: "29px",
    letterSpacing: "o.33px",
    fontFamily: "Calibri",
    src: `
      url(${Calibri}) format('ttf')
    `,
    "@media (min-width:600px)": {},
    [theme.breakpoints.down("md")]: {
      padding: "22px 0px",
    },
  },
  contentSmailImgDiv: {
    width: "140px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    boxShadow:
      "0 5px 20px rgba(154,160,185,.20), 0 15px 40px rgba(166,173,201,.2);",
    "&:hover": {
      boxShadow: "0px 2px 13px 6px rgba(224, 108, 38, 0.309709);",
      background: "0px 2px 13px 6px rgba(224, 108, 38, 0.309709);",
    },
  },
  contentSmailImage: {
    width: "100%",
    padding: "2px 16px",
    boxShadow:
      "0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2);",
  },
  boldTypeHeadline: {
    fontWeight: 800,
    fontFamily: "Gotham Rounded Medium",
  },
  getStarted: {
    width: "100%",
    margin: "0 auto",
    textAlign: "center",
    backgroundColor: "#FEFEFE",
    border: "1px solid #E7E7E7",
    [theme.breakpoints.down("xs")]: {
      margin: "72px auto 24px",
    },
    [theme.breakpoints.only("sm")]: {
      margin: "4rem auto 24px",
    },
    [theme.breakpoints.only("md")]: {
      margin: "3rem auto 24px",
    },
  },
});

export default WhatWeDoLogo;
