import React, { useState } from "react";
import { directors } from "./OurPeopleData";
import Container from "@material-ui/core/Container";
import { Modal } from "./Modal";
import "./About.css";
const Director = () => {
  const [showModal, setShowModal] = useState(false);
  const [personId, setPersonId] = useState();

  const openModal = () => {
    setShowModal((prev) => !prev);
  };
  return (
    <div>
      <Container>
        <section className="ourpeoplestakeholdercontainer">
          {directors.map((director) => {
            const { name, img, id } = director;
            return (
              <article
                key={id}
                id={`${id}`}
                onClick={() => {
                  openModal();
                  setPersonId(director.id);
                }}
                className="article-width flex-article-ourpeople"
                style={{ cursor: "pointer" }}
              >
                <div style={{ width: 233, height: 180 }}>
                  <img
                    src={img}
                    alt="management-team"
                    className="our-people-img"
                  />
                </div>
                <div>{name}</div>
              </article>
            );
          })}
          <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            personId={personId}
            section="director"
          />
        </section>
      </Container>
    </div>
  );
};

export default Director;
