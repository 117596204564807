import team1 from "../../assets/modal/team1.png";
import team2 from "../../assets/modal/team2.png";
import team3 from "../../assets/modal/team3.png";
import team6 from "../../assets/modal/team6.png";
import team8 from "../../assets/modal/team8.png";
import team9 from "../../assets/modal/team9.png";
import team10 from "../../assets/modal/team10.jpg";
import team11 from "../../assets/modal/team11.png";
import team12 from "../../assets/modal/team12.jpg";

import teamp from "../../assets/modal/img-plc.png";
import "./About.css";

export const people = [
  {
    img: team1,
    name: (
      <p className="name-styles-stakeholders">
        <span className="name-styles-stakeholders-bolded">Idris</span> Ibrahim
      </p>
    ),
    title: "Group Managing Director",
    id: "idris",
    description: `Idris is a seasoned professional with experience in
    Accounting, Financial Auditing, Advisory services, Risk
    Assessment and Evaluation from the Oil & Gas and the Financial
    Sectors.He holds a first degree in Accounting and Masters in
    Professional Accountancy from Louisiana Tech University (USA).
    He has worked with Ernst and Young (EY) in the US, KPMG and
    AMCON in Nigeria before venturing out as an Entrepreneur. He
    is chiefly responsible for the strategic expansion of the
    organization, which has led to the creation of other
    successful financial firms under the parent company - Canary
    Point Corporate Services, where he serves as the Group
    Managing Director. He is committed to the highest ethical
    standards and brings a network of skills, contacts and
    resources to CPCSL.`,
  },
  {
    img: team2,
    name: (
      <p className="name-styles-stakeholders">
        <span className="name-styles-stakeholders-bolded">Tolu</span> Oke
      </p>
    ),
    title: "Chief Operating Officer",
    id: "tolu",
    description: `Tolu serves as chief operating officer at Canary Point Corporate services with over eighteen years' work experience in FMCG, Financial Services and Consulting.
    She holds a BSc in Botany from the Obafemi Awolowo University lie-lfe, Nigeria, and a masters degree (MBA) in Business Administration from the University of Surrey, Guildford, UK. She has a Professional Diploma in HRM and is an Associate member  of the Chartered	Institute of Personnel Management. She has served in executive positions with multinationals such as Coca-Cola Nigeria Limited, Actis (Africa) Nigeria, a private equity firm. Prior to joining Canary Point Corporate Services, she worked in a HR Consulting firm and served as a director in
    Elbeo Limited, a Research and Strategy Consulting firm.
    `,
  },
  {
    img: team3,
    name: (
      <p className="name-styles-stakeholders">
        <span className="name-styles-stakeholders-bolded">Adewale</span> Ogunlolu
      </p>
    ),
    title: "Chief Financial Officer",
    id: "adewale",
    description: 'Adewale is a corporate finance manager with 10+years’ experience of accounting and Corporate Finance experience in Financial Institutions and money lending companies. Fully knowledgeable in Financial and Enterprise risk management, general accounting, payroll, budgeting, Corporate Finance, Treasury operations and credit risk management'
  },
  {
    img: team6,
    name: (
      <p className="name-styles-stakeholders-last-four">
        <span className="name-styles-stakeholders-bolded">Emmanuel</span> Bassey
      </p>
    ),
    title: <p className="post-text">Head,Strategy &Innovation</p>,
    id: "emmanuel",
    description: `Emmanuel serves as the Group Head, Strategy and Innovations refining the organisation strategy, brand management and marketing, including oversight of related implementation planning and execution activities. He holds a Higher National Diploma in Business Administration from the Polytechnic, Ibadan
    and MBA from the Obafemi Awolowo University ile-lfe, Nigeria. He is a certified Marketing Communication expert with local and international trainings from Advans Group Network, Paris. Prior to joining Canary Point Corporate Services, he served in a Multinational Financial Institution, Advans Group, leading Consumer Research, Market Insights, Brand and Marketing strategy and Pioneered innovative financial products across Advans Group Affiliates.
    `,
  },
];

export const directors = [
  {
    img: team11,
    name: (
      <p
        className="name-styles-stakeholders-for-svg"
        style={{ position: "relative", top: "1.3rem" }}
      >
        <span className="name-styles-stakeholders-bolded">Julius</span> Owotuga
      </p>
    ),
    description: `Mr. Julius Owotuga is the Group Executive Director, Geregu Power.
    He is a trained Chartered Accountant and fellow of the Institute of Chartered Accountants of Nigeria, an Associate Member of Chartered Institute of Taxation of Nigeria, a Chartered Management Accountant and a Fellow of the Institute  of Credit dministration. He was at Africa Finance Corporation (AFC) where he had responsibilities for the Corporation’s Assets and Liabilities Management function and also deputized for the Treasurer. Prior to joining Geregu Power,
    he was the CFO at Forte Oil.`,
    id: "julius",
  },
  {
    img: team1,
    name: (
      <p
        className="name-styles-stakeholders"
        style={{ position: "relative", top: "1.3rem" }}
      >
        <span className="name-styles-stakeholders-bolded">Idris</span> Ibrahim
      </p>
    ),
    id: "idris",
    description: `Idris is a seasoned professional with experience in
    Accounting, Financial Auditing, Advisory services, Risk
    Assessment and Evaluation from the Oil & Gas and the Financial
    Sectors.He holds a first degree in Accounting and Masters in
    Professional Accountancy from Louisiana Tech University (USA).
    He has worked with Ernst and Young (EY) in the US, KPMG and
    AMCON in Nigeria before venturing out as an Entrepreneur. He
    is chiefly responsible for the strategic expansion of the
    organization, which has led to the creation of other
    successful financial firms under the parent company - Canary
    Point Corporate Services, where he serves as the Group
    Managing Director. He is committed to the highest ethical
    standards and brings a network of skills, contacts and
    resources to CPCSL.`,
  },
  {
    img: team12,
    name: (
      <p
        className="name-styles-stakeholders"
        style={{ position: "relative", top: "1.3rem" }}
      >
        <span className="name-styles-stakeholders-bolded">Adeniyi</span> Duale
      </p>
    ),
    id: "adeniyi",
    description: `Mr. Adeniyi Duale is an astute lawyer with experience on advising on various aspects of corporate and commercial matters which include but are not limited to Capital Market, Media and Technology, Mergers and Acquisitions, Private Equity and Business Regulatory and Advisory. He is a member of the Section
    on Business Law of the Nigerian Bar Association, the Association of International
    Petroleum Negotiators (AIPN) and the Energy Institute.
    He is a graduate of the Lagos State University.
    He is the Managing Partner at Duale, Ovia & Alex-Adedipe. 
    `,
  },
  {
    img: teamp,
    name: (
      <p
        className="name-styles-stakeholders-for-svg"
        style={{ position: "relative", top: "1.3rem" }}
      >
        <span className="name-styles-stakeholders-bolded">Matthew</span> Coker
      </p>
    ),
    id: "matthew",
    description: `With over 27 years experience in various capitalities in the private sector, Mr. Matthew Coker was a former
    head of Total Quality Management at Access Bank and Head, Trade Services at First Bank. He is currently the Group Head, Asset Management
    at AMCON. He is an alumnus of the Advance Management Program (INSEAD), Harvard Business School Executive Program and University of Benin.`,
  },
  {
    img: team10,
    name: (
      <p
        className="name-styles-stakeholders"
        style={{ position: "relative", top: "1.3rem" }}
      >
        <span className="name-styles-stakeholders-bolded">Olumide</span> Soyombo
      </p>
    ),

    id: "olumide",
    description: `Mr. Olumide Soyombo is an astute Business information and Data Professional with a history
     of demonstrated results in the Information Technology and Services I ndustry. Skilled in Data Privacy, 
     Requirements Analysis, Software Licensing, Databases, and PL/SQL he has managed projects from a wide range 
     of industries and has vast experience in ERP and Business Intelligence. He holds a Bachelor’s Degree in 
     Systems Engineering from Lagos State University and a Master’s Degree in Business and Information Technology.
    He is a Co-founder of BlueChip Technologies Limited.`,
  },
  {
    img: teamp,
    name: (
      <p
        className="name-styles-stakeholders"
        style={{ position: "relative", top: "1.3rem" }}
      >
        <span className="name-styles-stakeholders-bolded">Princess</span>{" "}
        Erediauwa{" "}
      </p>
    ),
    description: `Princess Erediauwa Abieyuwa is the Property Management Head at Asset Management Corporation of Nigeria. She is one
      of the foremost Executives who has been instrumental in turning  around operations at AMCON.`,
    id: "princess",
  },
  {
    img: team9,
    name: (
      <p
        className="name-styles-stakeholders"
        style={{ position: "relative", top: "1.3rem" }}
      >
        <span className="name-styles-stakeholders-bolded">Iyinoluwa</span>{" "}
        Aboyeji
      </p>
    ),
    id: "iyin",
    description: `Mr. Iyinolua Aboyeji is the Founder and General Partner of Future Africa. He is an entrepreneur in the public interest. Prior
     to helping co-found and lead Flutterwave, he co-founded Andela-Africa's largest engineering organization with over 1000 software engineers which has received investment from Mark Zuckerberg and Google Ventures amongst others.
    He holds a Bachelor’s Deg ree in Legal Studies from the University of Waterloo; and sits on the board of several institutions including 
    Paris’ Share Africa Project, Rainbow Educational Services Limited and Filmo Realty.
    `,
  },
];
