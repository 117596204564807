import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Grid } from "@material-ui/core";
import whatToDoStyle from "./WhatWeDoStyle";
import LightSpeed from "react-reveal/LightSpeed";
import HomeNewsLetter from "components/HomePageFolder/HomeNewsLetter";
import { Investment } from "./what-to-do-components/Investment";
import { Microfinance } from "./what-to-do-components/Microfinance";
import { Bureau } from "./what-to-do-components/Bureau";
import Products from "./what-to-do-components/Products";
import { Remittance } from "./what-to-do-components/Remittance";

const useStyles = makeStyles(whatToDoStyle);

const WhatWeDo = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid>
        <Grid item className={classes.headerBackgroungImg}>
          <Grid item xs={11} sm={6} md={5} className={classes.headerTextBox}>
            <LightSpeed left>
              <Typography className={classes.headerHeadline}>
                What We Do
              </Typography>
            </LightSpeed>
          </Grid>
        </Grid>
        <Container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={11} md={8} className={classes.headlineContentGrid}>
                <Typography className={classes.headlineContentTypo}>
                  We provide a diversified range of financial services through
                  our subsidiaries such as MSME financing, Assets Financing,
                  Trade financing, Remittances, Investment Banking and Bureau De
                  change.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Grid
          item
          xs={11}
          sm={11}
          md={11}
          lg={10}
          className={classes.contentBodyGrid}
        >
          <Investment />
          <Microfinance />
          <Remittance />
          <Bureau />
          <Products />
          <HomeNewsLetter />
        </Grid>
      </Grid>
    </div>
  );
};

export default WhatWeDo;
