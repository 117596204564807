import React, { useLayoutEffect } from "react";
import FoundationStatement from "components/CanaryFoundation/FoundationStatement";
import CanaryFountionBanner from "../components/CanaryFoundation/CanaryFountionBanner";

const CanaryFoundation = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <CanaryFountionBanner />
      <FoundationStatement />
    </>
  );
};

export default CanaryFoundation;
