import React, { useState, useEffect } from "react";
import styles from "./CanaryFountionBanner.module.css";
import Container from "@material-ui/core/Container";
import LightSpeed from "react-reveal/LightSpeed";
import axios from "axios";
import { Images } from "../../shared/themes";
import { BASE_URL } from "../../config";
import { Link } from "react-router-dom";

import ClampLines from "react-clamp-lines";

const FoundationCard = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const checkScreenSize = () => {
    // window.addEventListener("resize", () => {
    if (window.innerWidth < 546) {
      setNumLines(3);
    } else if (window.innerWidth < 769) {
      setNumLines(5);
    } else if (window.innerWidth > 769) {
      setNumLines(8);
    }
    // });
  };

  let lines;

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 546) {
        // eslint-disable-next-line
        lines = 3;
      } else if (window.innerWidth < 769) {
        lines = 5;
      } else if (window.innerWidth > 769) {
        lines = 8;
      }
    });
  }, [lines]);

  const [numLines, setNumLines] = useState(lines);

  useEffect(() => {
    getAllData();
  }, []);

  useEffect(() => {
    checkScreenSize();
  }, [numLines]);

  const getAllData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/csrs`);
      const data = response.data;
      setData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  // const truncate = (str, no_words) => {
  //   return str.split(" ").splice(0, no_words).join(" ");
  // };

  return (
    <div style={{ background: "white" }}>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // marginTop: 100,
          }}
        >
          <h3 style={{ color: "#666" }}>Loading...</h3>
        </div>
      )}
      {data.length ? (
        <Container>
          {data
            .sort((a, b) => a.order - b.order)
            .map((item, i) => {
              const imageUrl = item.images.length
                ? BASE_URL + item.images[0].url
                : Images.canaryPointLogo;
              return (
                <div key={i} className={styles.foundationcardglobalcontainer}>
                  <LightSpeed right>
                    <p className={styles.foundationcarddescription}>
                      <span className={styles.foundationcarddescriptionbolded}>
                        {item.title}
                      </span>
                    </p>
                  </LightSpeed>
                  <Link to={`/canary-foundation/${item.id}`}>
                    <div className={styles.foundationbordercontainer}>
                      <div className={styles.imgfoundationcontainer}>
                        <img
                          className={styles.imagesty}
                          src={imageUrl}
                          alt="Make money"
                          width="100%"

                          // height="100%"
                        />
                      </div>

                      <div className={styles.sidetextoffoundationcard}>
                        <ClampLines
                          text={item.body}
                          id="really-unique-id"
                          lines={numLines}
                          ellipsis="..."
                          buttons={false}
                          className="custom-class"
                          innerElement="p"
                        />
                        {/* {item.body} */}
                        {/* {item.body.length > 250 */}
                        {/* ?  */}
                        {/* {truncate(item.body, wordCount)}... */}

                        {/* : item.body} */}
                        {/* <br /> */}
                        <div style={{ marginTop: 10 }}>
                          <Link
                            to={`/canary-foundation/${item.id}`}
                            className={styles.bodyText} 
                          >
                            Read More...
                          </Link>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
        </Container>
      ) : null}
      {!data.length && !loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: 50,
          }}
        >
          <h3 style={{ color: "#666" }}>
            No Content Available, check back later !
          </h3>
        </div>
      )}
    </div>
  );
};

export default FoundationCard;
