import React from "react";
import Container from "@material-ui/core/Container";
import styles from "./About.module.css";
import LightSpeed from "react-reveal/LightSpeed";
const AboutBanner = ({ toogleourpeople }) => {
  return (
    <div className={styles.aboutBanner}>
      <Container>
        <LightSpeed left>
          {toogleourpeople ? (
            <h2 className={styles.aboutbannertext}>
              We are deliberate in ensuring that our people and resources are
              geared towards being on top of innovation and market changes
            </h2>
          ) : (
            directorsTextForBanner.textBanner
          )}
        </LightSpeed>
      </Container>
    </div>
  );
};

export default AboutBanner;

const directorsTextForBanner = {
  textBanner: (
    <p className={styles.directorsTextForBanner}>
      We believe Africans deserve quality, excellent, and{" "}
      <strong className={styles.directorsTextForBannerbolded}>
        exceptional financial service
      </strong>
      <p className={styles.lasttext}>
        We are deliberate in ensuring that our people and resources are geared
        towards being on top of innovation and market changes
      </p>
    </p>
  ),
};
