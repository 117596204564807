import React from "react";
import HomeCarousel from "../components/HomePageFolder/HomeCarousel";
import HomeNewsLetter from "components/HomePageFolder/HomeNewsLetter";
import HomeSubsidiaries from "components/HomePageFolder/HomeSubsidiaries";
import WhatWeDoHome from "components/HomePageFolder/WhatWeDoHome";
import TeamBondingEvent from "components/MediaFolder/TeamBondingEvent";

import "react-responsive-modal/styles.css";

const Home = () => {
  return (
    <>
      <HomeCarousel />
      <WhatWeDoHome />
      <HomeSubsidiaries />
      <TeamBondingEvent morerecent="More Recent" />
      <HomeNewsLetter />
    </>
  );
};

export default Home;
