import React, { useState, useEffect, useLayoutEffect } from "react";
import axios from "axios";
import HomeNewsLetter from "components/HomePageFolder/HomeNewsLetter";
import MediaBanner from "components/MediaFolder/MediaBanner";
import MediaNav from "components/MediaFolder/MediaNav";
import RecentCardMedia from "components/MediaFolder/RecentCardMedia";
import Container from "@material-ui/core/Container";
import styles from "components/HomePageFolder/Homepage.module.css";
import stylesmedia from "components/MediaFolder/Media.module.css";

import { BASE_URL } from "../config";

const Media = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [indexCount, setIndexCount] = useState(6);
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    getAllBlogPosts();
  }, []);

  const getAllBlogPosts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/articles`);
      const data = response.data;
      setBlogData(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const truncate = (str, no_words) => {
    return str.split(" ").splice(0, no_words).join(" ");
  };

  return (
    <div>
      <MediaBanner active="Recent" />
      <MediaNav active="recent" />
      <div className={styles.recentcontainer}>
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 100,
            }}
          >
            <h3 style={{ color: "#666" }}>Loading...</h3>
          </div>
        )}
        {blogData.length ? (
          <Container>
            <div className={stylesmedia.flexcontainerrecentcard}>
              {
                // eslint-disable-next-line
                blogData.map((blog, index) => {
                  const imageUrl = BASE_URL + blog.images[0].url;
                  if (index < indexCount) {
                    return (
                      <RecentCardMedia
                        linkTo={`/media/news/${blog.id}`}
                        key={index}
                        id={blog.id}
                        title={truncate(blog.title, 4)}
                        body={blog.body}
                        image={imageUrl}
                      />
                    );
                  }
                })
              }
            </div>
            {blogData.length > 6 && (
              <>
                {indexCount <= 6 && (
                  <button
                    onClick={() => setIndexCount(blogData.length)}
                    className={styles.viewmorebtn}
                  >
                    View More
                  </button>
                )}
                {indexCount > 6 && (
                  <button
                    onClick={() => setIndexCount(6)}
                    className={styles.viewmorebtn}
                  >
                    View Less
                  </button>
                )}
              </>
            )}
          </Container>
        ) : null}
        {!blogData.length && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 50,
            }}
          >
            <h3 style={{ color: "#666" }}>
              No Content Available, check back later !
            </h3>
          </div>
        )}
      </div>
      <HomeNewsLetter />
    </div>
  );
};

export default Media;

const ras = "";

const ans = ras && console.log("Rasheed developer");
console.log(ans);
