import React from "react";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Images } from "../shared/themes";
import Container from "@material-ui/core/Container";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import TwitterIcon from "@material-ui/icons/Twitter";
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import RoomIcon from "@material-ui/icons/Room";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  containerStyles: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      textAlign: "center",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      textAlign: "center",
    },
  },
  footerHeader: {
    color: "#FFFFFF",
    fontSize: "16px",
    paddingTop: "50px",
    paddingBottom: "20px",
    textTransform: "uppercase",
    fontFamily: "Calibri",
    fontWeight: 400,
    lineHeight: "28px",
  },
  footerText: {
    paddingTop: "12px",
    fontSize: "14px",
    fontFamily: "Calibri",
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
      fontSize: "14px",
    },
    "&:hover": {
      color: "blue",
    },
  },
  footerTextpolicy: {
    paddingTop: "12px",
    fontSize: "14px",
    fontFamily: "Calibri",
    color: "#FFFFFF",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      paddingTop: "0",
      fontSize: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: "0",
      fontSize: "14px",
    },
  },
  logoStyles: {
    maxWidth: "250px",
    paddingTop: "30px",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "120px",
      paddingLeft: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px",
      paddingLeft: "14px",
    },
  },
  socialIcons: {
    color: "white",
    paddingTop: "50px",
  },
  footText: {
    color: "#ffffff",
  },
  contactIcons: {
    paddingRight: "12px",
    fontSize: "32px",
  },
  imgfooter: {
    marginRight: "60px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "0",
    },
  },
}));

const Footer = () => {
  const classes = useStyles();
  const history = useHistory();

  function mediaRoute() {
    history.push("/media/blog");
  }

  return (
    <>
      <div
        style={{
          background: "#131747",
          height: "auto",
          paddingBottom: "30px",
        }}
      >
        <Grid container justify="space-between" alignItems="center">
          <Container className={classes.containerStyles}>
            <Grid item md={3} xs={12} className={classes.imgfooter}>
              <img
                src={Images.completeLogo}
                alt="Logo"
                className={classes.logoStyles}
              />
              <Box className={classes.footerTextpolicy}>
                We're leveraging technology to build the best solutions to your
                financial problem
              </Box>
              <div className={classes.socialIcons}>
                <a
                  href="https://www.instagram.com/canarypointholdings/"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.footText}
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://www.facebook.com/canarypointholdings"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.footText}
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.linkedin.com/company/canarypointholdings/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.footText}
                >
                  <LinkedInIcon />
                </a>
                <a
                  href="https://twitter.com/canarypointhldg"
                  target="_blank"
                  rel="noreferrer"
                  className={classes.footText}
                >
                  <TwitterIcon />
                </a>
              </div>
            </Grid>
            <Grid item md={2} xs={12} className={classes.textgrid}>
              <Box className={classes.footerHeader}>SUBSIDIARIES</Box>
              <Box
                className={classes.footerText}
                onClick={() => window.open("https://sebastianbdc.com/")}
              >
                Sebastian BDC
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => window.open("https://www.swiftallied.com/")}
              >
                Swift&Allied Partners
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => window.open("https://www.assetsmfb.com/")}
              >
                Assets Microfinance Bank
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => window.open("https://www.instantcashng.com/")}
              >
                Instant Cash
              </Box>
              <Box className={classes.footerText}>monieTree</Box>
            </Grid>
            <Grid item md={2} xs={12} className={classes.textgrid}>
              <Box className={classes.footerHeader}>MEDIA</Box>
              <Box className={classes.footerText} onClick={mediaRoute}>
                Blog
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => history.push("/media/news")}
              >
                News
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => history.push("/media/events")}
              >
                Events
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => history.push("/media/gallery")}
              >
                Gallery
              </Box>
            </Grid>
            <Grid item md={2} xs={12} className={classes.textgrid}>
              <Box className={classes.footerHeader}>Company</Box>
              <Box
                className={classes.footerText}
                onClick={() => history.push("/about-us")}
              >
                About
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => history.push("/privacy")}
              >
                Privacy Policy
              </Box>
              <Box
                className={classes.footerText}
                onClick={() => history.push("/careers")}
              >
                Careers
              </Box>

              <Box
                className={classes.footerText}
                onClick={() => history.push("/Canary-foundation")}
              >
                Canary Foundation
              </Box>
            </Grid>
            <Grid item md={2} xs={12} className={classes.textgrid}>
              <Box className={classes.footerHeader}>CONTACT US</Box>
              <Box className={classes.footerTextpolicy}>
                <PhoneIcon className={classes.contactIcons} />
                01-6329481, 01-6329482
              </Box>
              <Box className={classes.footerTextpolicy}>
                <MailIcon className={classes.contactIcons} />
                info@canarypointcs.com
              </Box>
              <Box className={classes.footerTextpolicy}>
                <RoomIcon className={classes.contactIcons} />
                17a Dele Adedeji Street, Off Bisola Durosinmi-Etti Street, Lekki
                Phase I -Lagos. Nigeria
              </Box>
            </Grid>
          </Container>
        </Grid>
        <Box
          style={{ textAlign: "center", paddingTop: "50px" }}
          className={classes.footerTextpolicy}
        >
          Copyright © 2021 Canary Point Corporate Services
        </Box>
      </div>
    </>
  );
};

export default Footer;
